body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  body{
    @apply font-Body;
  }

  h1 {
    @apply text-[24px] md:text-[36px] lg:text-[48px];
    @apply font-Body;
  }

  h2{
    @apply text-[24px] lg:text-[40px] font-Body; 
  }

  h5 {
    @apply text-[12px] md:text-[16px] lg:text-[20px];
    @apply font-Body;
  }

  
}


map{
    width: 100%;
    height: 100%;
}